import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var exports = {};
!function (e, t) {
  exports = t(_react);
}(exports, function (e) {
  return (() => {
    "use strict";

    var t = {
      297: t => {
        t.exports = e;
      }
    },
        r = {};

    function o(e) {
      var i = r[e];
      if (void 0 !== i) return i.exports;
      var n = r[e] = {
        exports: {}
      };
      return t[e](n, n.exports, o), n.exports;
    }

    o.n = e => {
      var t = e && e.__esModule ? () => e.default : () => e;
      return o.d(t, {
        a: t
      }), t;
    }, o.d = (e, t) => {
      for (var r in t) o.o(t, r) && !o.o(e, r) && Object.defineProperty(e, r, {
        enumerable: !0,
        get: t[r]
      });
    }, o.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), o.r = e => {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    };
    var i = {};
    return (() => {
      o.r(i), o.d(i, {
        default: () => n
      });
      var e = o(297),
          t = o.n(e);

      const r = ({
        radius: r,
        progress: o,
        steps: i,
        cut: n,
        rotate: a,
        strokeWidth: s,
        strokeColor: l,
        fillColor: c,
        strokeLinecap: u,
        transition: d,
        pointerRadius: p,
        pointerStrokeWidth: k,
        pointerStrokeColor: f,
        pointerFillColor: m,
        trackStrokeColor: y,
        trackStrokeWidth: h,
        trackStrokeLinecap: C,
        trackTransition: v,
        counterClockwise: S,
        inverse: $,
        initialAnimation: b,
        initialAnimationDelay: g,
        className: x,
        children: P
      }) => {
        const [_, W] = (0, e.useState)(!1);
        (0, e.useEffect)(() => {
          let e;
          return b && (e = setTimeout(() => W(!0), g)), () => clearTimeout(e);
        }, []);

        const R = () => b && !_ ? 0 : o,
              j = () => {
          const e = p + k;
          return e > s && e > h ? 2 * e : s > h ? 2 * s : 2 * h;
        },
              w = 2 * r,
              E = w + j(),
              L = 2 * Math.PI * r,
              N = L / 360 * (360 - n);

        return t().createElement("div", {
          className: `RCP ${x}`,
          style: {
            position: "relative",
            width: `${E}px`
          }
        }, t().createElement("svg", {
          width: E,
          height: E,
          viewBox: `0 0 ${E} ${E}`,
          style: {
            transform: `rotate(${a}deg)`
          }
        }, h > 0 && t().createElement("circle", {
          cx: E / 2,
          cy: E / 2,
          r,
          fill: "none",
          stroke: y,
          strokeWidth: h,
          strokeDasharray: ((e, t) => b && !_ ? `0, ${t}` : `${e}, ${t}`)(N, L),
          strokeLinecap: C,
          className: "RCP__track",
          style: {
            transition: v
          }
        }), s > 0 && t().createElement("circle", {
          cx: E / 2,
          cy: E / 2,
          r,
          fill: c,
          stroke: l,
          strokeWidth: s,
          strokeDasharray: ((e, t) => {
            const r = R();
            return $ ? `${e / i * (i - r)}, ${t}` : S ? `${e * (r / 100)}, ${t}` : `${e}, ${t}`;
          })(N, L),
          strokeDashoffset: (e => {
            const t = R(),
                  r = e / i * (i - t);
            return $ ? S ? 0 : r - e : S ? -1 * r : r;
          })(N),
          strokeLinecap: u,
          className: "RCP__progress",
          style: {
            transition: d
          }
        }), p > 0 && t().createElement("circle", {
          cx: w,
          cy: "50%",
          r: p,
          fill: m,
          stroke: f,
          strokeWidth: k,
          className: "RCP__pointer",
          style: {
            transformOrigin: "50% 50%",
            transform: `rotate(${(() => {
              const e = R();
              return S ? (360 - n) / i * (i - e) : (360 - n) / i * e;
            })()}deg) translate(${j() / 2}px)`,
            transition: d
          }
        })), P || null);
      };

      r.defaultProps = {
        radius: 100,
        progress: 0,
        steps: 100,
        cut: 0,
        rotate: -90,
        strokeWidth: 20,
        strokeColor: "indianred",
        fillColor: "none",
        strokeLinecap: "round",
        transition: ".3s ease",
        pointerRadius: 0,
        pointerStrokeWidth: 20,
        pointerStrokeColor: "indianred",
        pointerFillColor: "white",
        trackStrokeColor: "#e6e6e6",
        trackStrokeWidth: 20,
        trackStrokeLinecap: "round",
        trackTransition: ".3s ease",
        counterClockwise: !1,
        inverse: !1,
        initialAnimation: !1,
        initialAnimationDelay: 0,
        className: ""
      };
      const n = r;
    })(), i;
  })();
});
export default exports;
export const ReactCustomizableProgressbar = exports.ReactCustomizableProgressbar;